import React, { useRef, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import "aos/dist/aos.css"
import AOS from "aos"

import fc1 from "../images/careers/carecar1.png"
import fc2 from "../images/careers/carecar2.png"
import fc3 from "../images/careers/carecar3.png"
import fc4 from "../images/careers/carecar4.png"
import fc5 from "../images/careers/carecar5.png"
import fc6 from "../images/careers/carecar6.png"
import fc7 from "../images/careers/carecar7.png"
import fc8 from "../images/careers/carecar8.png"
import fc9 from "../images/careers/carecar9.png"
import fc10 from "../images/careers/carecar10.png"
import fc11 from "../images/careers/carecar11.png"

import left from "../images/left.svg"
import right from "../images/right.svg"
import Slider from "react-slick"
import career1 from "../images/careers/banner.png"
import cai1 from "../images/cai1.png"
import cai2 from "../images/cai2.png"
import cai3 from "../images/cai3.png"
import cai4 from "../images/cai4.png"
import cai5 from "../images/cai5.png"
import cai6 from "../images/cai6.png"
import cai7 from "../images/cai7.png"
import cai8 from "../images/cai8.png"
import gptw from "../images/gptw.png"
import cdots1 from "../images/dots/careers/1.png"
import cdots2 from "../images/dots/careers/2.png"
import cdots3 from "../images/dots/careers/3.png"

const careers = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  const lafCar = useRef()
  const settingsLafCar = {
    className: "center",
    centerMode: true,
    dots: false,
    centerPadding: "60px",
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const settingsFel = {
    dots: true,
    dotsClass: "slick-dots fel-indi img-indi",
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  const impactPrevious = () => {
    lafCar.current.slickPrev()
  }
  const impactNext = () => {
    lafCar.current.slickNext()
  }
  return (
    <Layout pageName="careers">
      <SEO title="Careers" />
      <div className="bg-sec py-lg-5">
        <iframe
          width="100%"
          id="youtubeplayermob"
          className="d-block d-lg-none"
          frameBorder="0"
          src="https://www.youtube-nocookie.com/embed/kqBQoC_D08U"
          title="Teach For India"
        ></iframe>
        <Container className="py-4 py-lg-0">
          <div className="">
            <Row className="mb-lg-4 ">
              <div className="col-lg-6 text-left">
                <p className="ed-title bolder text-center text-lg-left">
                  Careers
                </p>
                <p className="fi-head text-center text-lg-left mb-0">
                  Join us to build
                  <br /> an equitable India
                </p>
              </div>
              <div className="col-lg-5 offset-lg-1 text-left text-lg-right gptw align-end align-sm-center pt-3 pt-lg-0">
                <img src={gptw} className="gptw-img" />
              </div>
            </Row>
          </div>
          <iframe
            width="100%"
            id="youtubeplayer"
            className="d-none d-lg-block"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/kqBQoC_D08U"
            title="Teach For India"
          ></iframe>
          <div className="col-12 pr-lg-0 text-lg-right pt-4 text-center">
            <a
              href="https://teachforindia.secure.force.com/careers/"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main butn-sp "
            >
              Join Staff
            </a>
          </div>
        </Container>
      </div>

      <div className="pt-4 py-lg-5 posrel">
        <Slider {...settingsLafCar} ref={lafCar} className="laf-car">
          <img src={fc1} className="h200" />

          <img src={fc2} className="h200" />

          <img src={fc3} className="h200" />

          <img src={fc4} className="h200" />

          <img src={fc5} className="h200" />

          <img src={fc6} className="h200" />

          <img src={fc7} className="h200" />

          <img src={fc8} className="h200" />

          <img src={fc9} className="h200" />

          <img src={fc10} className="h200" />

          <img src={fc11} className="h200" />
        </Slider>
        <div className="laf-gal-indi car-indi">
          <div className="left" onClick={impactPrevious}>
            <img src={left} />
          </div>
          <div className="right" onClick={impactNext}>
            <img src={right} />
          </div>
        </div>
      </div>
      <div className="py-5 patch-cont">
        <img
          src={cdots1}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="color-patcher d-none d-lg-block w-100"
        />
        <img
          src={cdots2}
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1000"
          className="color-patcher d-none d-lg-block w-100"
        />
        <img
          src={cdots3}
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="1000"
          className="color-patcher d-none d-lg-block w-100"
        />

        <div className="max-50 max-sm-100 px-3 px-lg-0 mx-auto text-center">
          <p className="fi-head">
            What is unique about working with Teach For India
          </p>
          <p className="fi-txt mb-3 mb-lg-5">
            Reaching our vision that all children will attain an excellent
            education requires us to have an exceptional team. Our Staff
            comprises of a diverse group of passionate, driven, and caring
            individuals who keep our children at the centre of their work,
            across the different functions they operate in. We&apos;ve been
            certified as a great place to work two years in a row. If you&apos;d
            like to contribute to the success of our movement, join our team
            today!
          </p>
          <p className="text-center">
            <a
              href="https://teachforindia.secure.force.com/careers/"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main butn-sm-sp butn-sp mr-3"
            >
              Join Staff
            </a>
          </p>
        </div>
      </div>

      <Container className="py-lg-5 img-car-container">
        <Slider {...settingsFel}>
          <img src={career1} className="w-100" />
        </Slider>
      </Container>

      <Container className="py-5">
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai1} className="career-icon mr-4" />
            <p className="fin-title mb-0">Meaningful Contribution</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              You will be addressing India&apos;s most pressing challenge and
              will have the ability to contribute significantly every day.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai2} className="career-icon mr-4" />
            <p className="fin-title mb-0">Diversity</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              You will work with a passionate, talented, and diverse team who
              will inspire and support you every day.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai3} className="career-icon mr-4" />
            <p className="fin-title mb-0">Culture</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              You will work in a culture of trust, care, and compassion.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai4} className="career-icon mr-4" />
            <p className="fin-title mb-0">Autonomy</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              You will have the autonomy to shape your own role, learning, and
              growth.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai5} className="career-icon mr-4" />
            <p className="fin-title mb-0">Flexibility</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              Our Staff work from all over the country in a culture of trust and
              focus on outcomes.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai6} className="career-icon mr-4" />
            <p className="fin-title mb-0">Health Care</p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              We provide medical insurance to our Staff with the option of
              buying family insurance for spouses and children.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai7} className="career-icon mr-4" />
            <p className="fin-title mb-0">
              Continuous learning and responsibility
            </p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              Our culture is one of constant learning, with opportunities to
              grow in effectiveness and responsibility.
            </p>
          </div>
        </div>
        <div className="carrer-cont row align-center">
          <div className="col-lg-5 d-flex align-center">
            <img src={cai8} className="career-icon mr-4" />
            <p className="fin-title mb-0">
              Extensive <br />
              network
            </p>
          </div>
          <div className="col-lg-7">
            <p className="fi-txt">
              Being part of the Teach For All network, our Staff get support
              from partner organisations globally, which include access to
              resources, opportunities, thought partnering and invitations to
              participate in conferences.
            </p>
          </div>
        </div>
        <p className="text-center pt-5">
          <a
            href="https://teachforindia.secure.force.com/careers/"
            target="_blank"
            rel="noreferrer noopener"
            className="butn butn-main butn-sm-sp butn-sp mr-3"
          >
            Join Staff
          </a>
        </p>
      </Container>
    </Layout>
  )
}

export default careers
